/* eslint-disable no-param-reassign */
import { flow, applySnapshot, getIdentifier } from 'mobx-state-tree';
import api from '~/api';
import { IDevicePofileModel } from './model';

export default (self: IDevicePofileModel) => ({
  fetch: flow(function* fetch() {
    try {
      self.startFetching();
      const { data } = yield self.sync(api.device_profiles.fetch, getIdentifier(self));
      applySnapshot(self, data[0]);
      self.finishFetching();
    } catch (error) {
      self.failFetching(error);
    }
  })
});
