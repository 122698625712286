/* eslint-disable no-param-reassign */
import { flow } from 'mobx-state-tree';
import { DateTime } from 'luxon';
import pick from 'lodash/pick';
import get from 'lodash/get';
import api from '~/api';
import type { IAnalyticsStore } from '.';

export default (self: IAnalyticsStore) => ({
  noesis: flow(function* noesis(values: any) {
    try {
      self.startSyncing();
      const { from, to, node_ids: nodeIds, prompt, parameters } = values;
      yield self.nodes.fetch({
        node_id: nodeIds,
        includes: ['profiles', 'data_points'],
        type: 'thiamis'
      });
      self.selected_parameters = parameters;
      if (self.selectedDataPointIdsByPresentName.length) {
        yield self.data_points.fetch({
          data_point_id: self.selectedDataPointIdsByPresentName,
          aggregate: '60',
          to: DateTime.fromISO(to).toMillis(),
          from: DateTime.fromISO(from).toMillis()
        });
      }
      const payload = {
        prompt: `${prompt}\n\nParameters: ${parameters.join('\n')}\n\nData:\n${JSON.stringify(self.data, null, 2)}`
      };
      const { data } = yield api.analytics.noesis(payload);
      self.response = data.response;
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  fetchAirQualityData: flow(function* fetchAirQualityData(values: any) {
    try {
      self.startSyncing();
      const { from, to, node_ids: nodeIds, parameters } = values;
      yield self.nodes.fetch({
        node_id: nodeIds,
        includes: ['profiles', 'data_points'],
        type: 'thiamis'
      });
      self.selected_parameters = parameters;
      if (self.selectedDataPointIdsByPath.length) {
        yield self.data_points.fetch({
          data_point_id: self.selectedDataPointIdsByPath,
          aggregate: '60',
          to: DateTime.fromISO(to).toMillis(),
          from: DateTime.fromISO(from).toMillis()
        });
      }
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  fetchPerimeterMonitorData: flow(function* fetchPerimeterMonitorData(values: any) {
    try {
      self.startSyncing();
      const { id } = values;
      const { data } = yield api.reports.read(id);
      const result = pick(get(data, 'data.params', {}), ['parameters', 'data', 'devices']);
      return {
        ...result,
        parameters: result.parameters.map(({ name, unit, data_point_ids }) => ({
          name,
          unit,
          ids: data_point_ids
        }))
      };
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
    return null;
  })
});
