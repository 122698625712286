import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CardContent, Card, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AnalyticsModel from '~/mst/models/analytics';
import Container from '~/components/container';

import Results from './results';
import FormContainer from './form_container';

function AnalyticsScreen() {
  const { t } = useTranslation();
  const analytics = useMemo(() => AnalyticsModel.create({}), []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" paragraph>
        {t('analytics.title')}
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormContainer model={analytics} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Results model={analytics} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default observer(AnalyticsScreen);
