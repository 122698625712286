import Select from '~/components/final_form/select';
import useLocales from '~/hooks/use_locales';
import { Button, Stack, Tooltip } from '@mui/material';
import InfoOutlined from '@mui/icons-material/HelpOutline';
import Modal from '~/components/modal';

import { observer } from 'mobx-react-lite';
import useMst from '~/hooks/use_mst';
import { useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import useModal from '~/hooks/use_modal';
import useFetch from '~/hooks/use_fetch';
import StandardModalContent from './standard_modal_content';

function StandardsSelect({ reportType }: { reportType: string }) {
  const { t } = useLocales();
  const { open, handleClose, handleOpen } = useModal();
  const { deviceProfiles } = useMst();
  const {
    values: {
      configuration: { standard }
    }
  } = useFormState({ subscription: { values: true } });

  useFetch(deviceProfiles.getById(standard));

  const handleDeviceProfileShow = useCallback(() => {
    if (standard) {
      handleOpen();
    }
  }, [standard, handleOpen]);

  const standardOptions = useMemo(() => {
    if (reportType === 'air_quality') {
      return deviceProfiles.valueLabelPairs.filter((option) => option.value !== 'aethair-crp-default');
    }
    if (reportType === 'air_quality_crp') {
      return deviceProfiles.valueLabelPairs.filter((option) => option.value === 'aethair-crp-default');
    }
    return deviceProfiles.valueLabelPairs;
  }, [reportType, deviceProfiles.valueLabelPairs]);

  return (
    <>
      <Stack direction="row" gap={1}>
        <Select options={standardOptions} label={t('attributes.report.standard')} name="configuration.standard" />
        <Tooltip title="See the standard values" placement="top">
          <Button onClick={handleDeviceProfileShow}>
            <InfoOutlined />
          </Button>
        </Tooltip>
      </Stack>
      <Modal title={`Standard: ${deviceProfiles.getById(standard)?.name}`} open={open} handleClose={handleClose}>
        <StandardModalContent standard={deviceProfiles.getById(standard)} handleClose={handleClose} />
      </Modal>
    </>
  );
}

export default observer(StandardsSelect);
