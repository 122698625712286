import { Instance } from 'mobx-state-tree';
import model from './model';
import views from './views';
import actions from './actions';

const DevicePofile = model.views(views).actions(actions);

export interface IDevicePofile extends Instance<typeof DevicePofile> {}

export default DevicePofile;
