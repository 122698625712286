/* eslint-disable no-param-reassign */
import { flow, getParent } from 'mobx-state-tree';
import openai from '~/services/openai';
import type { IChatsStore } from '~/mst/models/chats';
import { getStore } from '~/mst/utils';
import Message from '~/mst/models/gpt/thread/message';
import type { IChat } from '.';

export default (self: IChat) => ({
  destroy: flow(function* destroy() {
    self.startSyncing();
    try {
      yield openai.beta.assistants.del(self.assistant_id);
      const models = getParent<IChatsStore>(self, 2);
      models.remove(self);
      self.finishSyncing();
    } catch (error) {
      self.failSyncing(error);
    }
  }),
  start() {
    return new Promise((resolve, reject) => {
      try {
        self.startSyncing();
        let newMessage;
        const stream = openai.beta.threads.runs.stream(self.thread_id, { assistant_id: self.assistant_id });
        stream
          .on('messageCreated', (message) => {
            // console.log('messageCreated', message);
            newMessage = message;
          })
          .on('runStepCreated', () => {
            // console.log('runStepCreated', runStep);
          })
          .on('textCreated', () => {
            // console.log('textCreated', text);
          })
          .on('textDelta', (textDelta) => {
            // console.log('textDelta', snapshot);
            self.setThinking(false);
            self.setTextDelta(textDelta.value);
          })
          .on('end', () => {
            resolve({ status: 'completed' });
            if (newMessage) {
              const message = Message.create(newMessage);
              message?.setContent(self.text_delta);
              self.thread.messages.add(message);
              self.emptyTextDelta();
            }
          });

        self.finishSyncing();
      } catch (e) {
        console.error(e);
        self.failSyncing(e);
        reject(e);
      }
    });
  },
  emptyTextDelta() {
    self.text_delta = '';
  },
  setTextDelta(value) {
    self.text_delta += value;
  },
  setThinking(value) {
    self.thinking = value;
  },
  setProgress(value) {
    self.progress = value;
  },
  setThread(id) {
    self.thread_id = id;
  },
  createMessage: flow(function* createMessage(message) {
    try {
      self.thinking = true;
      yield self.thread.messages.create(message);
      if (self.thread.messages.size === 1) {
        const completion = yield openai.completions.create({
          model: 'gpt-3.5-turbo-instruct',
          prompt: `Summarize this message in 5 or less words: ${message}`,
          temperature: 0.4
        });
        const name = completion.choices[0].text;
        if (name) {
          self.thread.setName(name.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, '')); // Remove first and last characters if not alphanumeric
        }
      }
      yield self.start();
    } catch (e) {}
  }),
  createThread: flow(function* createThread() {
    try {
      self.startSyncing();
      const { id } = yield getStore(self).gpt.threads.create();
      self.thread_id = id;
      self.finishSyncing();
    } catch (e) {
      self.failSyncing(e);
    }
  })
});
