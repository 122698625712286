import { DateTime } from 'luxon';
import flatten from 'lodash/fp/flatten';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import compact from 'lodash/fp/compact';
import uniq from 'lodash/fp/uniq';
import groupBy from 'lodash/fp/groupBy';
import round from 'lodash/round';
import map from 'lodash/fp/map';
import reduce from 'lodash/reduce';
import meanBy from 'lodash/meanBy';
import { formatDateFromTs } from '~/utils/format_date';
import type { IAnalyticsStore } from '.';

export default (self: IAnalyticsStore) => ({
  get data() {
    return flow(
      map((dp) =>
        dp.measurements.map(([time, value]) => ({
          time: formatDateFromTs(time, { format: DateTime.DATETIME_MED }),
          parameter: dp.presentName,
          device: dp.node.presentName,
          range: dp.profileRange,
          round: dp.round,
          value: value.avg,
          status: dp.statusType(value.avg)
        }))
      ),
      flatten,
      groupBy('time'),
      map.convert({ cap: false })((values, time) => {
        return {
          Timestamp: time,
          ...reduce(
            groupBy('parameter', values),
            (acc, parameters, parameter) => {
              // eslint-disable-next-line no-param-reassign
              acc[parameter] = round(meanBy(parameters, 'value'), parameters[0]?.round);
              return acc;
            },
            {}
          )
        };
      })
    )(self.data_points.toArray);
  },
  get dataWithDevices() {
    return flow(
      map((dp) =>
        dp.measurements.map(([time, value]) => ({
          time: formatDateFromTs(time, { format: DateTime.DATETIME_MED }),
          parameter: dp.presentName,
          device: self.nodes.getById(dp.node_id)?.presentName,
          range: dp.profileRange,
          round: dp.round,
          value: value.avg,
          status: dp.statusType(value.avg)
        }))
      ),
      flatten,
      groupBy('time'),
      map.convert({ cap: false })((values, time) => {
        return {
          Timestamp: time,
          Devices: [
            ...reduce(
              groupBy('device', values),
              (acc, devices, device) => {
                // eslint-disable-next-line no-param-reassign
                acc.push({
                  Name: device,
                  Data: reduce(
                    groupBy('parameter', devices),
                    (acc1, parameters, parameter) => {
                      // eslint-disable-next-line no-param-reassign
                      acc1[parameter] = round(meanBy(parameters, 'value'), parameters[0]?.round);
                      return acc1;
                    },
                    {}
                  )
                });
                return acc;
              },
              []
            )
          ]
        };
      })
    )(self.data_points.toArray);
  },
  get selectedDataPoints() {
    return flow(
      map((node) => node.data_points.toArray),
      flatten
    )(self.nodes.toArray);
  },
  get selectedDataPointsByPath() {
    return flow(filter((dp) => self.selected_parameters.includes(dp.shortPath)))(self.selectedDataPoints);
  },
  get selectedDataPointsByPresentName() {
    return flow(filter((dp) => self.selected_parameters.includes(dp.presentName)))(self.selectedDataPoints);
  },
  get selectedDataPointIdsByPresentName() {
    return self.selectedDataPointsByPresentName.map((dp) => dp._id);
  },
  get selectedDataPointIdsByPath() {
    return self.selectedDataPointsByPath.map((dp) => dp._id);
  },
  get selectedDataPresentNames() {
    return flow(
      map((dp) => dp.presentName),
      uniq,
      compact,
      filter((name) => name !== 'AQ')
    )(self.selectedDataPointsByPath);
  }
});
