/* eslint-disable react/prop-types */
import { useState, useCallback, useMemo } from 'react';
import { useForm } from 'react-final-form';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { observer } from 'mobx-react-lite';
import throttle from 'lodash/throttle';
import { computed } from 'mobx';

import TextInput from '~/components/final_form/text_input';
import OrganizationsSelect from '~/components/organizations_select';
import Organizations from '~/mst/models/organizations';

import useLocales from '~/hooks/use_locales';

type FormProps = {
  input: any;
};

function Form(props: FormProps) {
  const { t } = useLocales();
  const { change } = useForm();
  const [organizations] = useState(Organizations.create());

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    throttle(async (e) => {
      if (e.target.value && e.target.value.length > 2) {
        change('external_id', e.target.value);
        await organizations.fetch({ query: e.target.value });
      }
    }, 300),
    [organizations]
  );

  const options = useMemo(
    () => computed(() => organizations.valueLabelPairs.map(({ model, ...rest }) => ({ ...rest, model, value: model.external_id }))),
    [organizations.valueLabelPairs]
  ).get();
  const { input } = props;

  return (
    <>
      <RadioGroup
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: 2 }}
      >
        <FormControlLabel sx={{ gridColumn: '1' }} value="email" control={<Radio />} label={t('attributes.user.email')} />
        <FormControlLabel sx={{ gridColumn: '1' }} value="external" control={<Radio />} label={t('models.organization')} />
      </RadioGroup>

      {input.value === 'email' ? (
        <TextInput name="email" label={t('attributes.user.email')} sx={{ gridColumn: '2 / 4' }} />
      ) : (
        <OrganizationsSelect
          freeSolo
          onInputChange={handleInputChange}
          name="external_id"
          label={t('models.organization')}
          loading={organizations.isFetching}
          sx={{ gridColumn: '2 / 4' }}
          options={options}
        />
      )}
    </>
  );
}

export default observer(Form);
