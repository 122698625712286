/* eslint-disable react/prop-types */
import { Field as FinalField, useForm } from 'react-final-form';
import { CardContent } from '@mui/material';
import { OnChange } from 'react-final-form-listeners';
import { observer } from 'mobx-react-lite';

import { ModalActions } from '~/components/modal';

import useLocales from '~/hooks/use_locales';
import useMst from '~/hooks/use_mst';
import Form from './form';

type ModalFormProps = {
  onClose: VoidFunction;
  handleSubmit: VoidFunction;
};

function ModalForm(props: ModalFormProps) {
  const { handleSubmit, onClose } = props;
  const { t } = useLocales();
  const { change } = useForm();
  const { auth } = useMst();

  const { isSyncing } = auth;

  return (
    <>
      <CardContent>
        <FinalField name="option">{({ input }) => <Form input={input} />}</FinalField>
        <OnChange name="option">
          {(value) => {
            if (value === 'email') {
              change(`external_id`, undefined);
            } else {
              change(`email`, undefined);
            }
          }}
        </OnChange>
      </CardContent>
      <ModalActions
        disabled={isSyncing}
        loading={isSyncing}
        onClose={onClose}
        onSave={handleSubmit}
        saveLabel={t('base.buttons.design_access_context')}
      />
    </>
  );
}

export default observer(ModalForm);
