import { Instance, SnapshotOut } from 'mobx-state-tree';
import CollectionArrayProcessor from '~/mst/models/abstract/collection_array_processor';
import model from './model';

const DevicePofilesStore = model;

export interface IDevicePofilesStore extends Instance<typeof DevicePofilesStore> {}
export interface IDevicePofilesStoreSnapshot extends SnapshotOut<typeof DevicePofilesStore> {}

export default CollectionArrayProcessor(DevicePofilesStore);
