import React, { useMemo } from 'react';
import { Typography, Box, Divider, Paper, Grid, Chip, CardContent } from '@mui/material';
import { IDevicePofile } from '~/mst/models/device_profile';
import { observer } from 'mobx-react-lite';
import { ModalActions } from '~/components/modal';
import LoadingBox from '~/components/loading_box';
import { PATHS } from '~/utils/constants';
import useDataPoints from '../parameters/hooks/use_data_points';

interface RangeItem {
  lt?: number;
  gt?: number;
  lte?: number;
  gte?: number;
  status: number;
}

interface ParameterOption {
  label: string;
  value: string;
  model: {
    shortPath: string;
    unit?: string;
    [key: string]: any;
  };
}

// Helper function to format range conditions
const formatRangeCondition = (rangeItem: RangeItem): string => {
  const conditions: string[] = [];

  if ('lt' in rangeItem) {
    conditions.push(`< ${rangeItem.lt}`);
  }

  if ('gt' in rangeItem) {
    conditions.push(`> ${rangeItem.gt}`);
  }

  if ('lte' in rangeItem) {
    conditions.push(`≤ ${rangeItem.lte}`);
  }

  if ('gte' in rangeItem) {
    conditions.push(`≥ ${rangeItem.gte}`);
  }

  return conditions.join(' and ');
};

function StandardModalContent({ standard, handleClose }: { standard: IDevicePofile; handleClose: () => void }) {
  const { parameterOptions, isFetching } = useDataPoints();

  const parameters = useMemo(
    () =>
      Object.entries(standard.ranges)
        .map(([path, rangeArray]) => {
          // Type assertion to help TypeScript understand the structure
          const typedOptions = parameterOptions as ParameterOption[];
          const parameter = typedOptions.find((option) => option.model?.shortPath === path);
          const paramName = parameter?.model?.name;
          const unit = parameter?.model?.unit;

          return { path, rangeArray, paramName, unit };
        })
        .filter(({ paramName, path }) => Boolean(paramName) && path !== PATHS.AQ)
        .sort((a, b) => a.paramName.localeCompare(b.paramName)),
    [parameterOptions, standard.ranges]
  );

  if (!standard || !standard.ranges) {
    return <Typography>No standard data available</Typography>;
  }

  return (
    <>
      <CardContent>
        {isFetching ? (
          <LoadingBox />
        ) : (
          <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
            {parameters.length ? (
              parameters.map(({ path, rangeArray, paramName, unit }) => (
                <Paper key={path} sx={{ p: 2, pb: 0 }}>
                  <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                    {paramName}
                  </Typography>
                  <Divider sx={{ mb: 1 }} />

                  {Array.isArray(rangeArray) &&
                    rangeArray.map((rangeItem: RangeItem, index: number) => {
                      const condition = formatRangeCondition(rangeItem);
                      const statusValue = rangeItem.status?.toString();
                      const statusName = standard.status_name?.[statusValue];

                      // Determine status color
                      let statusColor = 'default';
                      if (statusName === 'Good') statusColor = 'success';
                      if (statusName === 'Moderate') statusColor = 'warning';
                      if (statusName === 'Poor') statusColor = 'error';

                      return (
                        <Grid
                          container
                          key={`${path}-${statusName}-${condition}`}
                          sx={{
                            mb: 1.5,
                            alignItems: 'center',
                            p: 0.75,
                            borderRadius: 1,
                            bgcolor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'transparent'
                          }}
                        >
                          <Grid item xs={7}>
                            <Typography variant="body2">
                              {condition}
                              {unit ? ` ${unit}` : ''}
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sx={{ textAlign: 'right' }}>
                            <Chip label={statusName} size="small" color={statusColor as any} sx={{ fontWeight: 'medium' }} />
                          </Grid>
                        </Grid>
                      );
                    })}
                </Paper>
              ))
            ) : (
              <Typography variant="body2" color="text.secondary">
                No parameters available for this standard and selected devices.
              </Typography>
            )}
          </Box>
        )}
      </CardContent>
      <ModalActions onClose={handleClose} cancelLabel="Close" />
    </>
  );
}

export default observer(StandardModalContent);
