import { Box, Portal } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import compact from 'lodash/compact';
import { useSearchParams } from 'react-router-dom';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { IconButtonAnimate } from '~/components/animate';
import { Iconify } from '~/components/iconify';
import ChatModel from '~/mst/models/chat';
import useMst from '~/hooks/use_mst';
import toasts from '~/utils/toasts';
import useLocales from '~/hooks/use_locales';
import { computed } from 'mobx';
import { persistedStore } from '~/mst/store';
import AnalyticsModel from '~/mst/models/analytics';
import { ReportTypes } from '~/mst/models/report/types';
import PanelStyled, { PanelInnerStyled } from './styled';
import StartThread from './start_thread';

type GptContainerType = {
  isVisible: boolean;
  reportId: string;
  handleClose: () => void;
};

function GptContainer({ isVisible, handleClose, reportId }: GptContainerType) {
  const { reports, chats, deviceProfiles } = useMst();
  const report = reports.getById(reportId);
  const [, setSearchParams] = useSearchParams();

  const chat = useMemo(
    () =>
      computed(() => {
        if (report?.chatId && persistedStore.isRehydrated) {
          return chats.getById(report?.chatId) || chats.add(ChatModel.create({ id: report?.chatId }));
        }
        return null;
      }),
    [chats, report?.chatId]
  ).get();

  const { t } = useLocales();
  const analytics = useMemo(() => AnalyticsModel.create({}), []);

  const handleStartThread = useCallback(async () => {
    try {
      if (chat.isNew) {
        chat.startSyncing();
        chat.setProgress('Fetching report data...');
        switch (report?.configuration.type) {
          case ReportTypes.air_quality_crp:
          case ReportTypes.air_quality:
            {
              const profile = deviceProfiles.getById(report?.configuration.standard);
              await profile.fetch();
              await analytics.fetchAirQualityData({ ...report?.configuration?.toJSON(), parameters: Object.keys(profile.ranges) });
              await chats.createAirQualityChat(chat, {
                name: `NoesisGPT AI assistant for "${report?.name}" air quality report`,
                data: JSON.stringify(analytics?.dataWithDevices, null, 2),
                parameters: analytics.selectedDataPresentNames,
                devices: compact(report?.configuration.node_ids.map((id) => analytics.nodes.getById(id)?.presentName)),
                metadata: {
                  updated_at: report?.updatedAtIso
                }
              });
            }
            break;
          case ReportTypes.perimeter_monitor: {
            const data = await analytics.fetchPerimeterMonitorData(report?.toJSON());
            await chats.createPerimeterMonitorChat(chat, {
              name: 'Perimeter Monitoring Air Quality Data Analyst',
              data: JSON.stringify(data, null, 2),
              metadata: {
                updated_at: report?.updatedAtIso
              }
            });
            break;
          }
          default:
            toasts.error(t('notifications.errors.invalid_report_type'));
            break;
        }
        chat.finishSyncing();
        setSearchParams({ assistant: chat.assistant_id, thread: chat.thread_id });
      }
    } catch (e) {
      chat.failSyncing();
      toasts.error(t('notifications.errors.server_error'));
    }
  }, [report, analytics, chat, chats, t, setSearchParams, deviceProfiles]);

  // Don't render anything if not visible
  if (!isVisible) {
    return null;
  }

  return (
    <Portal>
      <ClickAwayListener onClickAway={handleClose}>
        <PanelStyled $isVisible={isVisible}>
          <Box sx={{ position: 'absolute', top: 15, left: -40, zIndex: 999999 }}>
            <IconButtonAnimate onClick={handleClose}>
              <Iconify icon="eva:close-fill" width={20} height={20} />
            </IconButtonAnimate>
          </Box>
          <PanelInnerStyled>
            <StartThread onStart={handleStartThread} model={chat} />
          </PanelInnerStyled>
        </PanelStyled>
      </ClickAwayListener>
    </Portal>
  );
}

export default observer(GptContainer);
