import { types as t, Instance, SnapshotOut } from 'mobx-state-tree';
import { ModelV2 as Model } from '~/mst/models/abstract/model';
import Fetchable from '~/mst/models/abstract/fetchable';

const DevicePofileModel = t.compose(
  t.model({
    name: t.string,
    status_name: t.frozen(),
    aq_index: t.frozen(),
    ranges: t.frozen()
  }),
  Model,
  Fetchable({ cache: { lifeTime: 300 } })
);

export interface IDevicePofileModel extends Instance<typeof DevicePofileModel> {}
export interface IDevicePofileModelSnapshotOut extends SnapshotOut<typeof DevicePofileModel> {}

export default DevicePofileModel;
