/* eslint-disable no-param-reassign */
import { Instance } from 'mobx-state-tree';
import DevicePofile from '~/mst/models/device_profile';
import CollectionArray from '~/mst/models/abstract/collection_array';

const DevicePofilesModel = CollectionArray(DevicePofile);

export interface IDevicePofilesModel extends Instance<typeof DevicePofilesModel> {}

export default DevicePofilesModel;
